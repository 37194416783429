import axios from "axios";
import React, { useState, useEffect } from "react";
const PriceDisplay = () => {
  const [price, setPrice] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=jesus&vs_currencies=usd",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const price = response.data.jesus.usd;
        const formattedPrice = price.toFixed(18).slice(0, -6);
        setPrice(formattedPrice);
      } catch (error) {
        console.error("Error fetching price:", error);
      }
    };

    const interval = setInterval(() => {
      fetchData();
    }, 60000); // Fetch price every 60 seconds

    fetchData(); // Initial fetch

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return <h2 style={{ color: "white" }}>{price} USD</h2>;
};

export default PriceDisplay;
