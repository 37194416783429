import React, { useState, useEffect } from "react";

const UnityGame = () => {
  const [loaded, setLoaded] = useState(false);
  const [gameUrl, setGameUrl] = useState(
    `${process.env.PUBLIC_URL}/game/index.html`
  );

  function handleOnLoad() {
    setLoaded(true);
  }

  return (
    <>
      {loaded ? (
        ""
      ) : (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            border: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Loading...</h2>
        </div>
      )}
      <iframe
        title="Unity WebGL Game"
        src={`${process.env.PUBLIC_URL}/game/index.html`}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          border: "0",
        }}
        onLoad={() => handleOnLoad()}
      />
    </>
  );
};

export default UnityGame;
